<template>
    <div class="page-table haberTanim  mainDiv" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.bultenler.tanimlama.title")}}
        </div>
        <el-row :gutter="10" v-loading="loading"
            :element-loading-text='$t("src.views.apps.bultenler.tanimlama.addLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="bultenForm" :rules="rulesBulten" ref="bultenForm" class="demo-ruleForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                     <el-tabs tab-position="left" class="language">
                        
                        <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft" >
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.baslik")' prop="baslik">:
                                        <el-input size="small" :disabled="disabled" v-model="bultenForm.baslik" v-on:blur="linkTespit();" :placeholder='$t("src.views.apps.bultenler.tanimlama.baslikPlace")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.altbaslik")' prop="altbaslik">:
                                        <el-input size="small"
                                            :disabled="disabled"
                                            v-model="bultenForm.altbaslik"
                                            :placeholder='$t("src.views.apps.bultenler.tanimlama.altBaslikPlace")'></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.kategoriler")' :prop="bultenForm.kategori == '' ? '' : 'kategori'">:
                                        <el-select v-model="bultenForm.kategori" style="width: 100%" size="mini">
                                            <div v-loading="catLoading">
                                                <el-option v-for="item in kategoriListe" :key="item.kategoriID" :label="item.baslik" :value="item.kategoriID"></el-option>
                                            </div>
                                        </el-select>

                                        <!-- <el-input size="small"
                                            v-model="bultenForm.kategori"
                                            :placeholder='$t("src.views.apps.bultenler.tanimlama.altbaslikPlace")'></el-input> -->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item :label='$t("src.views.apps.bultenler.tanimlama.icerik")' prop="icerik">:
                                        <ckeditor :editor="editor"
                                        :config="editorConfig"
                                        v-model="bultenForm.icerik"
                                        :disabled="disabled"
                                        @blur="seoIcerikTespit(); $store.commit('changeTabsClose', false)"></ckeditor>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                    <el-form-item>
                                        <el-checkbox v-model="videoVis">{{$t("src.views.apps.bultenler.guncelleme.videoOlsunMu")}}</el-checkbox>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                                <el-row :gutter="10">
                                    <el-col v-if="videoVis" style="float: right" :lg="12" :md="12" :sm="24" :xs="24">
                                        <el-collapse-item class=" animated fadeInDown card-shadow--medium" :title='$t("src.views.apps.bultenler.guncelleme.video")' name="2">
                                            <el-row>
                                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                    <el-form-item :label='$t("src.views.apps.bultenler.guncelleme.video")' prop="video">:
                                                        <el-input size="small"
                                                        type="textarea"
                                                        :rows="5"
                                                        v-model="bultenForm.video"
                                                        :placeholder='$t("src.views.apps.bultenler.guncelleme.videoURLGir")'></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </el-collapse-item>
                                    </el-col>
                                </el-row>
                        </el-collapse>
                        

                        <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.bultenler.guncelleme.resimAyar")' name="6">
                                <el-row>
                                    <Resim functionName="bultenTanimlaResim" :selectedsFromServer="selectedImagesFromServer"></Resim>
                                </el-row>
                                </el-collapse-item>
                        </el-collapse>

                        <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                                <draggable data-source="juju" class="list-group" group="a">
                                    <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.bultenler.guncelleme.seoAyar")' name="3">
                                        <seo :modulName="'haber'" :baslik="bultenForm.baslik" :icerik="bultenForm.icerik" v-on:changeSeo="handleSeo"></seo>
                                    </el-collapse-item>
                                </draggable>
                        </el-collapse>
                     </el-tabs>
                    
                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" name="8" :title='$t("src.views.apps.bultenler.tanimlama.durum")'>
                            <el-row>
                                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                    <el-form-item prop="durum">
                                        <el-select
                                            size="small"
                                            :disabled="disabled"
                                            v-model="bultenForm.durum"
                                            :placeholder='$t("src.views.apps.bultenler.tanimlama.durumPlace")'
                                            style="width: 100%">
                                            <el-option value="0" label="Taslak">{{$t("src.views.apps.bultenler.guncelleme.taslak")}}</el-option>
                                            <el-option value="1" label="Yayınla">{{$t("src.views.apps.bultenler.guncelleme.yayinla")}}</el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-button size="mini" v-on:click="bultenTanimlama('bultenForm',bultenForm.durum)"  class="onayBtn" type="primary">
                                    <label>Kaydet</label>
                                </el-button>
                                &nbsp;
                                <el-popover placement="bottom" width="300" v-model="visibleSil">
                                    <p>{{$t('src.views.apps.yonetici.tanimlama.formAlertP')}}</p>
                                    <div style="text-align: right; margin: 0">
                                        <el-button size="mini" type="primary" @click="resetForm('bultenForm')">
                                            {{$t('src.views.apps.yonetici.tanimlama.evet')}}
                                        </el-button>
                                        <el-button size="mini" @click="visibleSil = false;">
                                            {{$t('src.views.apps.yonetici.tanimlama.hayir')}}
                                        </el-button>
                                    </div>
                                    <el-button size="mini" slot="reference">
                                        Temizle
                                    </el-button>
                                </el-popover>
                            </el-row>
                        </el-collapse-item>


                        <draggable class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.bultenler.tanimlama.tarih")' name="10">
                                <el-row :gutter="5">
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                        <el-row>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-form-item style="position: relative;top: -25px"
                                                    :label='$t("src.views.apps.bultenler.tanimlama.baslamaTarihi")'
                                                    prop="tarih">
                                                    <el-date-picker  size="small" class="select-wide" type="datetime"
                                                        :disabled="disabled"
                                                        :placeholder='$t("src.views.apps.bultenler.tanimlama.tarihPlace")'
                                                        :picker-options="dateRangeOptions"
                                                        v-model="bultenForm.tarih"
                                                        format="yyyy-MM-dd HH:mm:ss"
                                                        value-format="yyyy-MM-dd HH:mm:ss">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-checkbox v-if="!checkedTarih" style="margin-bottom: 15px" v-model="checkedTarih">
                                                    {{$t("src.views.apps.bultenler.tanimlama.bitisTarihi")}}
                                                </el-checkbox>
                                                <el-col v-if="checkedTarih" :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                                    <el-form-item style="position: relative;top: -20px"
                                                        :label='$t("src.views.apps.bultenler.tanimlama.bitisTarihi")'
                                                        prop="bitisTarihi">:
                                                        <el-date-picker size="small" class="select-wide" type="datetime"
                                                            :disabled="disabled"
                                                            :placeholder='$t("src.views.apps.bultenler.tanimlama.bitisTarihiPlace")'
                                                            :picker-options="dateRangeOptions"
                                                            v-model="bultenForm.bitisTarihi"
                                                            format="yyyy-MM-dd HH:mm:ss"
                                                            value-format="yyyy-MM-dd HH:mm:ss">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                            <!-- <konumlar :modulName="'haber'" :resim="bultenForm.resimKonum" :menu="bultenForm.menuKonum" :galeri="bultenForm.galeriKonum" v-on:changeKonum="handleKonum"></konumlar> -->

                        </draggable>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from "vuedraggable";

    import bultenService from '../../../WSProvider/BultenService'
    import bultenKategoriService from '../../../WSProvider/BultenKategoriService'

    import notification from '../../../notification'
    import functions from '../../../functions'
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import Belge from '@/components/Belge.vue'
    import Seo from '@/components/Seo.vue'
    import Konumlar from '@/components/Konumlar.vue'
    import JQuery from 'jquery';

    let $ = JQuery;

    var moment = require('moment');
    let time = moment().format('YYYY-MM-DD HH:mm:ss');

    export default {
        name: "BultenTanimla",
        components: {
            draggable,
            Resim,
            Belge,
            Seo,
            Konumlar
        },
        watch: {
            imageList: function (val) {
                if (val.length === 1) {
                    this.bultenForm.resimKonum = "1";
                    this.bultenForm.galeriKonum = "0";
                } else if (val.length > 1) {
                    this.bultenForm.galeriKonum = "1";
                } else {
                    this.bultenForm.resimKonum = "0";
                    this.bultenForm.galeriKonum = "0";
                }
            },
        },
        computed: {
            imageList() {
                return this.bultenForm.resim
            }
        },
        mounted() {
            this.getKategoriCount();

            const self = this;
            EventBus.$on("bultenTanimlaResim", function(bool, imageArray) {
                if (bool) {
                    self.bultenForm.resim = imageArray;
                }
            });

            EventBus.$on("bultenTanimlaBelge", function(bool, belgeArray) {
                if (bool) {
                    self.bultenForm.belge = belgeArray;
                }
            });
        },
        data() {
            return {
                totalKategori: 0,
                catLoading:false,
                loading : false,
                selectedImagesFromServer: [],
                selectedFilesFromServer: [],
                path: bultenService.path,
                imagePath: bultenService.imagePath,
                belgePath: bultenService.belgePath,

                videoVis: false,
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr',
                },
                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', "11"],
                visibleSil: false,
                disabled: false,
                checkedTarih: false,
                kategoriListe: [],
                dateRangeOptions: {
                    firstDayOfWeek: 1
                },

                bultenForm: {
                    tarihler: "",
                    kategori: '',
                    baslik: '',
                    altbaslik: '',
                    video: "",
                    icerik: '',
                    resim: [],
                    belge: [],
                    link: '',
                    resimKonum: '0',
                    menuKonum: '0',
                    galeriKonum: '0',
                    tarih: time,
                    bitisTarihi: '',
                    durum: '1',
                    seoBaslik: '',
                    seoIcerik: '',
                },
                rulesBulten: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Bülten Başlığı Giriniz",
                        trigger: 'blur'
                    }],
                    kategori: [{
                        required: true,
                        message: "Lütfen Kategori Seçiniz",
                        trigger: ['blur', 'trigger']
                    }],
                    altbaslik: [{
                        required: true,
                        message: "Lütfen Bülten Alt Başlığı Giriniz",
                        trigger: 'blur'
                    }],
                    icerik: [{
                        required: true,
                        message: "Lütfen İçerik Giriniz",
                        trigger: 'blur'
                    }],
                    seoBaslik: [{
                        max: 70,
                        message: "Tavsiye edilen SEO Başlık limitini aştınız.",
                        trigger: 'change'
                    }],
                    seoIcerik: [{
                        max: 160,
                        message: "Tavsiye edilen SEO İçerik limitini aştınız.",
                        trigger: 'change'
                    }],
                },
            }
        },
        methods: {
            linkTespit() {
                this.bultenForm.seoBaslik = this.bultenForm.baslik.substring(0, 60);     
            },
            seoIcerikTespit() {
                this.bultenForm.seoIcerik = this.bultenForm.icerik.substring(0, 160);     
            },
            handleSeo(seoBaslik,seoIcerik) {
                this.bultenForm.seoBaslik = seoBaslik;
                this.bultenForm.seoIcerik = seoIcerik;
            },
            handleKonum(resimKonum,menuKonum,galeriKonum) {
                this.bultenForm.resimKonum = resimKonum;
                this.bultenForm.menuKonum = menuKonum;
                this.bultenForm.galeriKonum = galeriKonum;
            },
            
            getKategoriListe() {
                try{
                    this.catLoading = true;
                    bultenKategoriService.bKategoriListe(1, 0 , this.totalKategori).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token)
                            this.kategoriListe = response.data;
                            // this.bultenForm.kategori = response.data.length > 0 ? response.data[0].bkategoriID : '0'
                        }
                        this.catLoading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){  
                                this.kategoriListe = [];
                                this.bultenForm.kategori = "";
                            }
                            notification.Status("errorMsg", this, error.errMsg)
                        }else{
                            notification.Status(503, this)
                        }
                        this.catLoading = false;
                    })
                }catch(e){
                    this.catLoading = false;
                }
            },
            getKategoriCount() {
                try{
                    bultenKategoriService.bKategoriSayisi(1).then(response => {
                        if(response.status == 200){
                            localStorage.setItem("userDataDemirag", response.token);
                            this.totalKategori = response.data;
                            this.getKategoriListe();
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){  
                                this.totalKategori = 0
                            }
                        }else{
                            notification.Status(503, this)
                        }
                    })
                }catch(e){
                    
                }
            },
            bultenTanimlama(formName, durum) {
                if(this.validateForm(formName)){
                    try{
                        this.loading = true;
                        var resimUzunluk = this.bultenForm.resim.length;
                        var belgeUzunluk = this.bultenForm.belge.length;
                        var sunucudanEklenenResimler = this.selectedImagesFromServer.join(",");

                        //kategoriID, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimUzunluk, video, resimKonum, menuKonum, galeriKonum, durum, seoBaslik, seoIcerik, baslamaTarihi, bitisTarihi
                        bultenService.bultenTanimlama(this.bultenForm.kategori, this.bultenForm.baslik, this.bultenForm.altbaslik, this.bultenForm.icerik, sunucudanEklenenResimler, this.bultenForm.resim.length > 0 ? this.bultenForm.resim : "", resimUzunluk, this.bultenForm.video, this.bultenForm.resimKonum, this.bultenForm.menuKonum, this.bultenForm.galeriKonum, durum, this.bultenForm.seoBaslik, this.bultenForm.seoIcerik, this.bultenForm.tarih, this.bultenForm.bitisTarihi).then((response) => {
                            if(response.status == 200){
                                localStorage.setItem("userDataDemirag", response.token)
                                this.resetForm(formName)
                                EventBus.$emit("bultenListe", true)
                                this.sayfaKapat('bultentanimla')
                            }
                            this.loading = false;
                            notification.Status("success", this, response.msg)
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                notification.Status(503, this)
                            }
                            this.loading = false;
                        })
                    }catch(e){
                        this.loading = false;
                    }
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
            },

        }
    }

</script>
